import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Countdown = loadable(() => import('react-countdown'))

const CountdownTimer = ({ date }) => {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>This offer has expired</span>
        } else {
            // Render a countdown
            return (
                <div className="c-countdown">
                    <div>
                        <span className="c-countdown__number">{days}</span>
                        <span className="c-countdown__title">days</span>
                    </div>
                    <div>
                        <span className="c-countdown__number">{hours}</span>
                        <span className="c-countdown__title">hours</span>
                    </div>
                    <div>
                        <span className="c-countdown__number">{minutes}</span>
                        <span className="c-countdown__title">minutes</span>
                    </div>
                    <div>
                        <span className="c-countdown__number">{seconds}</span>
                        <span className="c-countdown__title">seconds</span>
                    </div>
                </div>
            )
        }
    }

    return <Countdown date={date} renderer={renderer} />
}

CountdownTimer.propTypes = {
    /**
     * Date to count down to
     */
    date: PropTypes.object.isRequired
}

CountdownTimer.defaultProps = {
    date: new Date('March 16, 2023 00:00:00')
}

export default CountdownTimer
